.leaflet-control-attribution.leaflet-control {
  display: none !important;
}

.positionRef {
  margin-top: -42px;
  z-index: 1000000;
  position: absolute;
  background: #ffffff75;
  border-radius: 5px;
  padding: 5px;
  font-weight: 100;
  font-size: 12px;
}
