.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 500;
  color: #262626;
  text-transform: uppercase;
  border: 1px solid #bfbfbf;
  border-radius: 50%;
  background-color: #e8e8e8;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s;
}

.group {
  margin-left: -7px;
}

.cluster-marker {
  display: flex;
}

.Ejecutando {
  color: #fafafa;
  border-color: #ad8b00;
  background-color: #d4b106;
}

.Programada {
  color: #fafafa;
  border-color: #531dab;
  background-color: #722ed1;
}

.Libre {
  color: #fafafa;
  border-color: #006d75;
  background-color: #13c2c2;
}

.gang-list {
  animation: none;
  margin-right: 10px;
}

.update-alert {
  position: absolute;
  bottom: 8px;
  left: 2px;
  font-size: 20px;
}

.alert-icon {
  color: #262626;
}

.circle-icon {
  color: #fff;
  font-size: 0.9em;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(255, 77, 79, 0.7);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 77, 79, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0px rgba(255, 77, 79, 0.7);
    box-shadow: 0 0 0 0px rgba(255, 77, 79, 0.7);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 77, 79, 0);
    box-shadow: 0 0 0 20px rgba(255, 77, 79, 0);
  }
}
