.Pendiente {
  color: #fafafa;
  border-color: #ad6800;
  background-color: #faad14;
}

.Recibida {
  color: #fafafa;
  border-color: #096dd9;
  background-color: #1890ff;
}

.Materiales-validados {
  color: #fafafa;
  border-color: #3949ab;
  background-color: #3f51b5;
}

.Aprobada {
  color: #fafafa;
  border-color: #7cb342;
  background-color: #8bc34a;
}

.Rechazada {
  color: #fafafa;
  border-color: #b71c1c;
  background-color: #c62828;
}

.Inspección {
  color: #fafafa;
  border-color: #1a237e;
  background-color: #283593;
}

.Cancelada {
  color: #fafafa;
  border-color: #616161;
  background-color: #757575;
}

.Ejecución {
  color: #fafafa;
  border-color: #1b5e20;
  background-color: #2e7d32;
}

.Pausada {
  color: #fafafa;
  border-color: #ff5722;
  background-color: #ff7043;
}

.Asignada {
  color: #fafafa;
  border-color: #f44336;
  background-color: #ef5350;
}

.Programada-T {
  color: #fafafa;
  border-color: #43a047;
  background-color: #4caf50;
}

.Activa {
  color: #fafafa;
  border-color: #006d75;
  background-color: #08979c;
}

.Alerta {
  color: #fafafa;
  border-color: #a8071a;
  background-color: #f5222d;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.type {
  color: #616161;
  border-color: #e0e0e0;
  background-color: #eeeeee;
}

.failed {
  color: #fafafa;
  border-color: #f44336;
  background-color: #ef5350;
}

.complementary {
  color: #fafafa;
  border-color: #607d8b;
  background-color: #607d8b;
}
